import React  from 'react';
import { Card, Button, OverlayTrigger, Tooltip, Row, Col, Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { observer } from 'mobx-react';
import './App.css';

import Header from './components/header/index.js';
import AboutMe from './components/about/';
import MyTargets from './components/myTargets/';
import MyWorks from './components/myWorks/';
import Services from './components/services/';
import Reviews from './components/reviews/';
import Contacts from './components/contacts/';
import Welcome  from './components/welcome/';
import Map  from './components/map/';
import PriceList  from './components/priceList/';
import CareTips from './components/careTips/';
import {useState, useEffect} from 'react';

import { StoreContext } from './contexts';

 function App() {


  const store = React.useContext(StoreContext);
  const { mainStore } = store;
  // function 
  return (
    <div className="App"       >
      {/* <Welcome opacity="0.1"/> */}
      <Col className="mainCol"   >
        <Header/>
        <br/>
        <h1 className='m-5 lobster-regularX'><b>Ортодонтическое лечение в <br/>Нижнем Новгороде и Москве</b></h1>
        {/* <h1 className='m-5 lobster-regularX'>Устанавливаю брекеты в Нижнем Новгороде и в Москве!</h1> */}
        <Row>
          <div className='hashTag'>#Брекеты</div> 
          <div className='hashTag'>#Элайнеры</div> 
          <div className='hashTag'>#Москва</div> 
          <div className='hashTag'>#Нижний Новгород</div>
        </Row>
        <br/>
        <AboutMe openCert={event => {mainStore.openCert(event)}}/>
        <MyTargets/>
        <MyWorks/>
        <Services/>
        <Reviews/>
        {/* <Map/> */}
        <PriceList/>
        <CareTips/>
        <Contacts /> 
        <div className='w-100 row'>
          <label className='gray w-100 p-3 mb-5' hidden>2024 Все изображения защищены авторским правом.</label>
          <label className='ml-0 gray mr-auto col-5'>Мой сайт носит информационный характер и является инструментом продвижения личного бренда.</label>
          <label className='ml-0 gray mr-auto col-5' hidden>Данный сайт не является сайтом медицинской организации, более подробную информацию вы можете найти в <div  className='btn-info' onClick={mainStore.openInfo} style={{width: '160px'}}>уголке потребителя</div> </label>
          <label className='gray ml-auto mr-3 p-0  mt-auto mb-0 col-3'>сайт разработан: <a href='https://t.me/mike_k' target='blank'>t.me/mike_k</a> </label>
        </div>
      </Col>
   
      <Modal 
        show={mainStore.curModalview === 'cert'} 
        size='lg'
        onClick={mainStore.closeCert}
        onHide={mainStore.closeCert}
        
      >
        <img src={'./imgs/' + mainStore.curCert + '.jpg'} onClick={mainStore.closeCert}/>
      </Modal>
      <Modal 
        show={mainStore.curModalview === 'info'} 
        size='lg'
        onClick={mainStore.closeCert}
        onHide={mainStore.closeCert}
  
        
      >
          <div className='w-100 p-5 column'>
            <b className='p-5'>Данный сайт является официальным сайтом врача-ортодонта Кудрявцевой Светланы Андреевны.</b>
            <p>Данный сайт не является сайтом медицинской организации.</p><p>Прием и осуществление профессиональной деятельности происходит у сторонних медицинских организаций, на основании договора по осуществлению медицинской деятельности.</p>
            <p>Лицензии и документы, разрешающие осуществление медицинской деятельности, находятся фактически на местах оказания услуг.</p>
            
            <b>Документы подтверждающие квалификацию:</b> 
            <a href='https://твойортодонт.рф/docs/edu2019.jpg' target='_blank'>Диплом специалиста "Врач-стоматолога"</a>
            <a href='https://твойортодонт.рф/docs/edu2023.jpg' target='_blank'>Диплом об окончании ординатуры по специальности "Ортодонтия"</a>
            <br/>
            <b>Контактный номер: 8 987 758 77 77</b>
            <b>Контактный e-mail: 89877587777@mail.ru</b>

          </div>
      </Modal>
    </div>
  );
}




export default observer(App);
