import React from "react";
import './index.css';

import pic1 from './careTip1.jpg';
import pic2 from './careTip2.jpg';
import pic3 from './careTip3.jpg';
import pic4 from './careTip4.jpg';
import pic5 from './careTip5.jpg';




const careTips = () => {
	return (
		<div className="careTips column">

            <h3 className="w-100">Правильная гигиена при ортодонтическом лечении:</h3>

            <div className="row-stack w-100 ">
                <div className="card">
                    <b className="p-2">НЕОБХОДИМО ТЩАТЕЛЬНО ЧИСТИТЬ ЗУБЫ</b>
                    <p>Минимум два раза в день (после завтрака и после ужина) втечение 5-10 минут. Желательно чистить зубы после каждого приема пищи. <br/><br/>Посещать врача-гигиениста 1 разв
2-4 месяца (частота визитов подбирается индивидуально)</p>
                </div>
                <div className="card">
                    <img src={pic1} />
                    <p>Чистка брекетов и зубов с помощью ортодонтической зубной щетки круговыми движениями, располагая щетку частично на десне, исключая горизонтальные движения.</p>
                </div> 

                <div className="card">
                    <img src={pic2} />
                    <p>Чистка внутренней и жевательной поверхности зубов обычной зубной щеткой.</p>
                </div> 

                <div className="card">
                    <img src={pic3} />
                    <p>Чистка пространства вокруг брекетов с помощью монопучковой щетки.</p>
                </div> 

                <div className="card">  
                    <img src={pic4} />
                    <p> Очистка зубных промежутков с помощью зубной нити с плотным кончиком (суперфлосс). Нить сначала вводится под дугу, а затем в межзубной промежуток.</p>
                </div>
                <div className="card">
                    <img src={pic5} />
                    <p>Очистка пространства под дугой и вокруг брекетов с помощью ершиков, подобранных врачом-гигиенистом.</p>
                </div>
            </div>
            <br/>
            <br/>
             <b className="mainTip"> Помните, что ортодонтическое леченеие-это совместная работа доктора и пациента. <br/>Следуйте всем рекомендациям своего врача, и все обязательно получится!</b>
		
            <br/>
            <br/>
            <h3>Что можно и нельзя есть после установки брекетов?</h3>
            <p>Ношение брекетов-отнюдь не повод отказываться от любимой еды, но все же следует придерживаться некоторых правил.</p>
                {/* <p>Ношение брекетов-отнюдь не повод отказываться от любимой еды, но все же следует придерживаться некоторых правил.<p> */}
            <br/>
            <div className="row-stack w-100 ">
                <div className="coltainer col-6 col-s-10 p-3">
                    <div className="cloud  cloud-red">
                        <h5 className="w-100">Продукты, которые следует исключить:</h5>
                        <div className=" row w-100">
                            <div className="column  col-6 col-s-12">
                                <b>Липнущие продукты</b>
                                <ul>
                                    <li>ириски</li>
                                    <li>тянучки</li>
                                    <li>халва</li>
                                    <li>нуга</li>
                                    <li>чипсы</li>
                                    <li>жевательная резинка</li>
                                </ul>
                            </div>
                            <div className="column  col-6 col-s-12">

                            <b>Твердые продукты</b>
                                <ul>
                                    <li>карамель</li>
                                    <li>сушки</li>
                                    <li>сухари</li>
                                    <li>орехи</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="coltainer col-6 col-s-10 p-3">
                    <div className="cloud  cloud-yellow ">
                        <h5 className="w-100">
                        Употреблять с осторожностью:</h5>
                        <div className=" row w-100">
                            <div className="column  col-6 col-s-12">
                                {/* <b>Липнущие продукты</b> */}
                                <ul>
                                    <li>твердые овощи</li>
                                    <li>фрукты</li>
                                    <li>жесткое мясо</li>
                                    <li>продукты требующие  откусывание следует резать на маленькие кусочки</li>
                                </ul>
                            </div>
                            <div className="column  col-6 col-s-12">

                            {/* <b className="m-auto p-1">Продукты с красителями</b> */}
                            <b className="m-auto p-1">Избегать холодной ИЛИ очень горячей пищи</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default careTips